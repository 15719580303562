<template>
  <base-container>
    <div class="loader">
      <cdek-spinner size="big" />
    </div>
  </base-container>
</template>

<script lang="ts" setup>
import BaseContainer from '@/components/BaseContainer.vue';
import { CdekSpinner } from '@cdek-ui-kit/vue';
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const locale = computed(() => store.getters.currentLocale);

onBeforeMount(() => {
  if (store.getters.isLoggedInCabinet) {
    window.location.pathname = `/${locale.value}/cabinet/`;
    return;
  }

  const url = new URL(window.location.origin + window.location.search);
  url.pathname = `/${locale.value}/cabinet/auth/`;

  window.location.replace(url.href);
});
</script>

<style scoped lang="scss">
.loader {
  padding: 40px 0;
  text-align: center;
}
</style>
